<template>
  <div>
    <b-button @click="showModal = true" variant="success"> يوجد مرفق </b-button>

    <b-modal v-model="showModal" centered :title="''" @hide="resetModal" hide-footer>
      <div class="text-center">
        <b-img rounded :src="fullImageUrl" alt="Image" fluid></b-img>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { BButton, BModal, BImg } from 'bootstrap-vue'

export default {
  name: 'ImageModal',
  components: {
    BButton,
    BModal,
    BImg
  },
  props: {
    imageUrl: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      showModal: false,
      mainProps: { width: 50, height: 50 }
    }
  },
  computed: {
    fullImageUrl() {
      return `${process.env.VUE_APP_API_LINK}${this.imageUrl}`
    }
  },
  methods: {
    resetModal() {
      // Reset modal state if needed
    }
  }
}
</script>

<style scoped>
b-img {
  max-width: 100%;
  height: auto;
}
</style>
