<template>
  <div>
    <b-button @click="showModal = true" variant="info">اضافة مرفق</b-button>
    <b-modal v-model="showModal" centered title="اضافة مرفق" hide-footer>
      <validation-observer ref="observer">
        <b-form @submit.prevent="validateBeforeUpload">
          <b-form-group>
            <validation-provider name="File" rules="required|image" v-slot="{ errors }">
              <b-form-file accept="image/*" v-model="selectedFile" :state="errors.length ? false : null"></b-form-file>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-button variant="primary" type="submit">اضافة</b-button>
          <b-button variant="secondary" @click="cancelUpload">الغاء</b-button>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import { BModal, BFormFile, BButton, BFormGroup, BForm } from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider, extend, localize } from 'vee-validate'
import { required, image } from 'vee-validate/dist/rules'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import api from '../../services/api'

// Add the validation rules
extend('required', required)
extend('image', image)

export default {
  components: {
    BModal,
    BForm,
    BFormFile,
    BButton,
    BFormGroup,
    ValidationObserver,
    ValidationProvider
  },

  data() {
    return {
      locale: 'ar',
      showModal: false,
      selectedFile: null
    }
  },
  props: {
    paymentChangeId: {
      type: Number
    },
    type: {
      type: String
    }
  },
  mounted() {
    // Switch to Arabic in validation
    localize(this.locale)
  },
  methods: {
    async validateBeforeUpload() {
      const isValid = await this.$refs.observer.validate()
      if (isValid) {
        this.uploadImage()
      }
    },
    async uploadImage() {
      const formData = new FormData()
      formData.append('File', this.selectedFile)
      if (this.type === 'coupon') {
        formData.append('CouponsPaymentsId', this.paymentChangeId ? this.paymentChangeId : null)
      } else if (this.type === 'expense') {
        formData.append('ExpensesPaymentsId', this.paymentChangeId ? this.paymentChangeId : null)
      }
      await api
        .post('api/Coupons/UploadCouponsPaymentsImage', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then((response) => {
          this.$emit('render-table')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'تم تحميل الصورة بنجاح',
              icon: 'CheckIcon',
              variant: 'success'
            }
          })
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'حدث خطأ أثناء تحميل الصورة',
              icon: 'XCircleIcon',
              variant: 'danger'
            }
          })
        })

      this.showModal = false
    },
    cancelUpload() {
      this.showModal = false
    }
  }
}
</script>
